<template>
  <div>
    <UButton
      color="white"
      :ui="{
        color: { white: { solid: 'md:ring-green md:ring-2' } },
        rounded: 'rounded-none md:rounded-md'
      }"
      class="focus:ring-white focus:ring-2 focus:ring-offset-2"
      v-bind="$attrs"
      @click="leaveSite"
    >
      Quick exit
    </UButton>
  </div>
</template>
<script setup lang="ts">
const leaveSite = () => {
  const googleUrl = 'https://www.bbc.co.uk/weather'

  // clear local storage
  localStorage.clear()

  // open google in a new tab and redirect current page
  const newWindow = window.open(googleUrl, '_blank')
  if (newWindow) {
    newWindow.focus()
  }
  window.location.replace(googleUrl)
}
</script>
