<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 56" fill="none">
    <path
      d="M1.48194 17.7171H1.92022C2.27502 17.7171 2.56721 17.42 2.56721 17.0592V9.0367H12.6894V17.0804C12.6894 17.4412 12.9815 17.7383 13.3363 17.7383H13.7746C14.1294 17.7383 14.4216 17.4412 14.4216 17.0804V1.12033C14.4216 0.759532 14.1294 0.462402 13.7746 0.462402H13.3363C12.9815 0.462402 12.6894 0.759532 12.6894 1.12033V7.29637H2.56721V1.12033C2.56721 0.759532 2.27502 0.462402 1.92022 0.462402H1.48194C1.12715 0.462402 0.834961 0.759532 0.834961 1.12033V17.0592C0.834961 17.4412 1.12715 17.7171 1.48194 17.7171Z"
      fill="white"
    />
    <path
      d="M30.7628 0.462402H30.3245C29.9697 0.462402 29.6776 0.759532 29.6776 1.12033V10.968C29.6776 12.0929 29.4689 13.0267 29.0723 13.7483C28.6549 14.5124 28.0705 15.1066 27.2983 15.5523C25.7539 16.4437 23.6878 16.4437 22.1433 15.5523C21.3711 15.1066 20.7868 14.5124 20.3694 13.7483C19.9728 13.0479 19.785 12.1141 19.785 10.968V1.12033C19.785 0.759532 19.4928 0.462402 19.138 0.462402H18.6997C18.3449 0.462402 18.0527 0.759532 18.0527 1.12033V10.9256C18.0527 12.39 18.3241 13.621 18.8667 14.5973C19.4302 15.616 20.265 16.4437 21.3085 17.0592C22.352 17.6535 23.4999 17.9718 24.7521 17.9718C25.9835 17.9718 27.1522 17.6747 28.1749 17.0804C29.2184 16.4862 30.0324 15.6584 30.6167 14.6185C31.1594 13.6422 31.4515 12.39 31.4515 10.9468V1.12033C31.4098 0.759532 31.1176 0.462402 30.7628 0.462402Z"
      fill="white"
    />
    <path
      d="M44.9335 16.571C45.5179 16.0829 45.9979 15.4674 46.3318 14.767C46.6658 14.0667 46.8536 13.3026 46.8536 12.4961C46.8536 11.2652 46.4779 10.1828 45.7266 9.2277C45.2674 8.65466 44.7457 8.23019 44.1613 7.89062C44.4118 7.65716 44.6205 7.40248 44.8292 7.10535C45.2883 6.40497 45.5388 5.59848 45.5388 4.72831C45.5388 4.09161 45.4135 3.47613 45.1422 2.90309C44.8709 2.33005 44.5161 1.86314 44.0361 1.45989C43.2221 0.780738 42.1577 0.441162 40.8638 0.441162H35.9175C35.5627 0.441162 35.2705 0.738291 35.2705 1.09909V17.038C35.2705 17.3988 35.5627 17.6959 35.9175 17.6959H41.3021C42.7839 17.7171 44.0152 17.3351 44.9335 16.571ZM42.9091 6.65965C42.4499 7.08412 41.7403 7.31758 40.8012 7.31758H37.0028V2.20271H40.822C41.7612 2.20271 42.4708 2.41495 42.93 2.86064C43.4935 3.37001 43.7856 4.00671 43.7856 4.77076C43.7648 5.51358 43.4935 6.12907 42.9091 6.65965ZM37.0028 9.03669H41.2395C42.4708 9.03669 43.3891 9.35504 44.057 10.013C44.7666 10.7133 45.1005 11.5411 45.1005 12.5173C45.1005 12.9843 44.9961 13.4512 44.8292 13.8969C44.6413 14.3426 44.3909 14.7246 44.057 15.0429C43.3891 15.7009 42.4708 16.0192 41.2395 16.0192H37.0028V9.03669Z"
      fill="white"
    />
    <path
      d="M16.1746 30.1753C15.7572 29.0929 15.1728 28.1379 14.4215 27.3526C13.691 26.5673 12.7936 25.9306 11.7501 25.4849C10.7065 25.0392 9.6004 24.8058 8.41078 24.8058C6.8455 24.8058 5.40544 25.1878 4.11147 25.9731C2.83838 26.7371 1.81573 27.8195 1.08526 29.1778C0.354797 30.5149 0 32.0218 0 33.656C0 35.2902 0.354797 36.7971 1.08526 38.1342C1.81573 39.4925 2.81751 40.5749 4.11147 41.3389C5.38457 42.103 6.8455 42.5062 8.41078 42.5062C9.57953 42.5062 10.7065 42.2727 11.7501 41.827C12.7936 41.3814 13.691 40.7446 14.4215 39.9594C15.1519 39.1741 15.7363 38.2191 16.1746 37.1367C16.592 36.0543 16.8007 34.887 16.8007 33.656C16.8216 32.4463 16.592 31.2577 16.1746 30.1753ZM8.43165 40.8083C7.17943 40.8083 6.03155 40.49 5.0089 39.8745C3.98625 39.2378 3.19318 38.3888 2.62967 37.3064C2.06617 36.224 1.77399 34.9931 1.77399 33.6772C1.77399 32.3614 2.06617 31.1304 2.62967 30.048C3.19318 28.9656 3.98625 28.1167 5.0089 27.48C6.01068 26.8645 7.17943 26.5461 8.43165 26.5461C9.37082 26.5461 10.2683 26.7371 11.0822 27.0979C11.8961 27.4587 12.6057 27.9681 13.1901 28.5836C13.7745 29.2203 14.2336 29.9843 14.5676 30.8545C14.9015 31.7247 15.0685 32.6797 15.0685 33.656C15.0685 34.6323 14.9015 35.5873 14.5676 36.4575C14.2336 37.3277 13.7745 38.0917 13.1901 38.7284C12.6266 39.3439 11.917 39.8533 11.0822 40.2353C10.2683 40.6173 9.37082 40.8083 8.43165 40.8083Z"
      fill="white"
    />
    <path
      d="M29.052 25.0393H20.9751C20.6203 25.0393 20.3281 25.3364 20.3281 25.6972V41.6361C20.3281 41.9969 20.6203 42.294 20.9751 42.294H21.4134C21.7682 42.294 22.0604 41.9969 22.0604 41.6361V33.5924H27.4658C27.8206 33.5924 28.1128 33.2953 28.1128 32.9345V32.5312C28.1128 32.1704 27.8206 31.8733 27.4658 31.8733H22.0604V26.7584H29.0311C29.3859 26.7584 29.6781 26.4613 29.6781 26.1005V25.6972C29.6989 25.3364 29.4068 25.0393 29.052 25.0393Z"
      fill="white"
    />
    <path
      d="M54.4089 41.6361V25.6972C54.4089 25.3364 54.1167 25.0393 53.7619 25.0393H53.3237C52.9689 25.0393 52.6767 25.3364 52.6767 25.6972V31.8733H42.5545V25.6972C42.5545 25.3364 42.2623 25.0393 41.9075 25.0393H41.4692C41.1144 25.0393 40.8223 25.3364 40.8223 25.6972V41.6361C40.8223 41.9969 41.1144 42.294 41.4692 42.294H41.9075C42.2623 42.294 42.5545 41.9969 42.5545 41.6361V33.5924H52.6767V41.6361C52.6767 41.9969 52.9689 42.294 53.3237 42.294H53.7619C54.1167 42.294 54.4089 41.9969 54.4089 41.6361Z"
      fill="white"
    />
    <path
      d="M72.3365 27.3526C71.606 26.5673 70.7086 25.9306 69.6651 25.4849C68.6216 25.0392 67.5154 24.8058 66.3258 24.8058C64.7605 24.8058 63.3205 25.1878 62.0265 25.9731C60.7534 26.7371 59.7308 27.8195 59.0003 29.1778C58.2907 30.5149 57.915 32.0218 57.915 33.656C57.915 35.2902 58.2698 36.7971 59.0003 38.1342C59.7308 39.4925 60.7325 40.5536 62.0265 41.3389C63.2996 42.103 64.7605 42.5062 66.3258 42.5062C67.4946 42.5062 68.6216 42.2727 69.6651 41.827C70.7086 41.3814 71.606 40.7446 72.3365 39.9594C73.067 39.1741 73.6513 38.2191 74.0896 37.1367C74.507 36.0543 74.7157 34.887 74.7157 33.656C74.7157 32.425 74.507 31.2577 74.0896 30.1753C73.6513 29.0929 73.067 28.1591 72.3365 27.3526ZM66.3258 26.5461C67.265 26.5461 68.1624 26.7371 68.9764 27.0979C69.7903 27.4587 70.4999 27.9681 71.0843 28.5836C71.6686 29.2203 72.1278 29.9843 72.4617 30.8545C72.7956 31.7247 72.9626 32.6797 72.9626 33.656C72.9626 34.6323 72.7956 35.5873 72.4617 36.4575C72.1278 37.3277 71.6686 38.0917 71.0843 38.7284C70.5208 39.3651 69.8112 39.8533 68.9764 40.2353C68.1624 40.5961 67.265 40.7871 66.3258 40.7871C65.0736 40.7871 63.9257 40.4687 62.9031 39.8533C61.9013 39.2166 61.0873 38.3676 60.5238 37.2852C59.9603 36.2028 59.6682 34.9931 59.6682 33.656C59.6682 32.3189 59.9603 31.1092 60.5238 30.0268C61.0873 28.9444 61.8804 28.0954 62.9031 27.4587C63.9257 26.8645 65.0736 26.5461 66.3258 26.5461Z"
      fill="white"
    />
    <path
      d="M84.3373 25.0393H78.7232C78.3684 25.0393 78.0762 25.3364 78.0762 25.6972V41.6361C78.0762 41.9969 78.3684 42.294 78.7232 42.294H79.1614C79.5162 42.294 79.8084 41.9969 79.8084 41.6361V34.0593H84.3373C85.8191 34.0593 87.0087 33.6348 87.8644 32.8071C88.7618 31.9369 89.221 30.8333 89.221 29.5599C89.221 28.2653 88.7618 27.1829 87.8644 26.3127C87.0087 25.4638 85.8191 25.0393 84.3373 25.0393ZM79.8084 32.319V26.7584H84.2956C85.3182 26.7584 86.0695 27.0131 86.5913 27.5012C87.1757 28.0743 87.4679 28.7534 87.4679 29.5387C87.4679 30.324 87.1757 30.9819 86.6122 31.5549C86.0696 32.0643 85.3182 32.319 84.2956 32.319H79.8084Z"
      fill="white"
    />
    <path
      d="M101.263 40.5748H94.0627V33.6135H99.4681C99.8229 33.6135 100.115 33.3164 100.115 32.9556V32.5523C100.115 32.1915 99.8229 31.8944 99.4681 31.8944H94.0627V26.7795H101.033C101.388 26.7795 101.68 26.4824 101.68 26.1216V25.7184C101.68 25.3576 101.388 25.0604 101.033 25.0604H92.9566C92.6018 25.0604 92.3096 25.3576 92.3096 25.7184V41.6572C92.3096 42.018 92.6018 42.3151 92.9566 42.3151H101.242C101.597 42.3151 101.889 42.018 101.889 41.6572V41.254C101.91 40.8719 101.618 40.5748 101.263 40.5748Z"
      fill="white"
    />
    <path
      d="M3.23467 52.9482C3.29728 52.8845 3.33902 52.8208 3.38076 52.7571C3.50598 52.5449 3.58947 52.3327 3.58947 52.078C3.58947 51.887 3.54772 51.7172 3.48511 51.5474C3.40163 51.3776 3.29728 51.2503 3.17206 51.1229C2.94248 50.9319 2.62943 50.8258 2.27463 50.8258H0.688477V55.7072H2.39985C2.81726 55.7072 3.17206 55.6011 3.44337 55.3676C3.61034 55.2191 3.73556 55.0493 3.83991 54.8583C3.94426 54.6673 3.986 54.4338 3.986 54.2003C3.986 53.8608 3.88165 53.5424 3.67295 53.2665C3.52685 53.1604 3.40163 53.0331 3.23467 52.9482ZM1.23111 51.3988H2.25376C2.5042 51.3988 2.69204 51.4625 2.81726 51.5686C2.96335 51.696 3.04683 51.8658 3.04683 52.078C3.04683 52.2902 2.98422 52.4388 2.81726 52.5874C2.69204 52.6935 2.5042 52.7571 2.25376 52.7571H1.23111V51.3988ZM3.33902 54.6036C3.29728 54.7309 3.2138 54.8158 3.13032 54.9007C2.96335 55.0705 2.71291 55.1554 2.37898 55.1554H1.23111V53.2877H2.37898C2.71291 53.2877 2.96335 53.3726 3.13032 53.5424C3.31815 53.7334 3.40163 53.9457 3.40163 54.2216C3.40163 54.3701 3.38076 54.4762 3.33902 54.6036Z"
      fill="white"
    />
    <path
      d="M6.11478 52.7996L4.84168 50.847H4.17383L5.82259 53.3939V55.7285H6.38609V53.3939L8.03486 50.847H7.367L6.11478 52.7996Z"
      fill="white"
    />
    <path
      d="M14.7764 54.5188C14.6095 54.731 14.4008 54.9008 14.1503 55.0282C13.9207 55.1555 13.6494 55.2192 13.3781 55.2192C13.0024 55.2192 12.6894 55.1343 12.3972 54.9645C12.105 54.7947 11.8963 54.5612 11.7293 54.2641C11.5624 53.967 11.4998 53.6274 11.4998 53.2666C11.4998 52.9058 11.5833 52.5875 11.7293 52.2903C11.8754 51.9932 12.105 51.7597 12.3763 51.59C12.8564 51.2928 13.5033 51.2504 14.046 51.4838C14.2755 51.59 14.4634 51.7385 14.6303 51.9083L14.7764 52.0781L15.173 51.6961L15.0269 51.5263C14.8182 51.2928 14.5469 51.1018 14.2547 50.9745C13.5659 50.6561 12.7103 50.7198 12.0633 51.1018C11.6876 51.314 11.4163 51.6324 11.2076 52.0144C10.9989 52.3964 10.8945 52.8209 10.8945 53.2666C10.8945 53.6062 10.9571 53.9458 11.0824 54.2429C11.2076 54.54 11.3746 54.8159 11.5833 55.0494C11.792 55.2616 12.0633 55.4526 12.3555 55.58C12.6476 55.7073 12.9816 55.771 13.3364 55.771C13.6912 55.771 14.046 55.6861 14.359 55.5163C14.6721 55.3465 14.9434 55.1343 15.1521 54.8584L15.2773 54.6886L14.839 54.349L14.7764 54.5188Z"
      fill="white"
    />
    <path
      d="M19.5348 52.7572H16.7799V50.847H16.2373V55.7285H16.7799V53.309H19.5348V55.7285H20.0775V50.847H19.5348V52.7572Z"
      fill="white"
    />
    <path
      d="M23.104 50.847H22.9996H22.8744L20.8291 55.7285H21.4343L22.0605 54.2216H24.0431L24.6901 55.7285H25.2954L23.2501 50.847H23.104ZM22.29 53.6485L23.0622 51.8445L23.8136 53.6485H22.29Z"
      fill="white"
    />
    <path
      d="M28.5095 53.5636C28.426 53.4575 28.3217 53.3726 28.2173 53.309C28.113 53.2453 27.9878 53.1816 27.8625 53.118C27.7791 53.0755 27.6747 53.0331 27.5704 52.9906L27.2782 52.8845C27.1947 52.8633 27.1112 52.8208 27.0277 52.7784C26.9442 52.7359 26.8816 52.6935 26.819 52.651C26.7564 52.6086 26.7147 52.5661 26.6521 52.5025C26.6103 52.46 26.5894 52.3964 26.5686 52.3539C26.5477 52.2902 26.5268 52.2053 26.5268 52.1205C26.5268 52.0143 26.5477 51.9294 26.5686 51.8445C26.6103 51.7596 26.6521 51.696 26.6938 51.6323C26.7564 51.5686 26.819 51.5262 26.9025 51.4837C26.986 51.4413 27.0486 51.3988 27.1321 51.3988C27.5704 51.2927 27.9043 51.4413 28.1756 51.7172L28.3426 51.887L28.7182 51.4625L28.5721 51.314C28.1547 50.8895 27.6121 50.6985 27.0069 50.847C26.8816 50.8683 26.7564 50.9319 26.6521 50.9956C26.5268 51.0593 26.4225 51.1442 26.3181 51.2503C26.2138 51.3564 26.1303 51.4837 26.0677 51.6323C26.0051 51.7809 25.9842 51.9507 25.9842 52.1205C25.9842 52.269 26.0051 52.3964 26.0468 52.5237C26.0886 52.651 26.1512 52.7572 26.2347 52.8633C26.3181 52.9482 26.3807 53.0331 26.4851 53.0967C26.5686 53.1604 26.6729 53.2241 26.7982 53.2877C26.9025 53.3302 27.0069 53.3939 27.1112 53.4151L27.4243 53.5424C27.5495 53.5849 27.633 53.6273 27.7165 53.6698C27.9043 53.7547 28.0504 53.882 28.1547 54.0093C28.2591 54.1367 28.3008 54.2852 28.3008 54.4763C28.3008 54.646 28.2591 54.7946 28.1756 54.9007C28.0921 55.0281 27.9878 55.113 27.8625 55.1766C27.7373 55.2403 27.5704 55.2828 27.4034 55.2828C26.9234 55.2828 26.5686 55.0705 26.2973 54.6673L26.172 54.455L25.7129 54.7946L25.8381 54.9644C26.0259 55.2615 26.2555 55.4738 26.506 55.6011C26.7564 55.7497 27.0486 55.8133 27.3825 55.8133C27.7791 55.8133 28.1339 55.686 28.4052 55.4313C28.6765 55.1766 28.8226 54.8371 28.8226 54.4338C28.8226 54.264 28.8017 54.1155 28.76 53.9669C28.6765 53.7971 28.6139 53.6698 28.5095 53.5636Z"
      fill="white"
    />
    <path d="M30.4502 50.847H29.8867V55.7285H30.4502V50.847Z" fill="white" />
    <path
      d="M35.1042 54.5824L32.2032 50.847H32.0362H31.9319H31.8066V55.7285H32.3701V51.9931L35.292 55.7285H35.4381H35.5424H35.6677V50.847H35.1042V54.5824Z"
      fill="white"
    />
    <path
      d="M38.9443 53.3089H40.614V54.6036C40.4679 54.7946 40.2592 54.9432 40.0505 55.0493C39.8 55.1766 39.5287 55.2403 39.2365 55.2403C38.8608 55.2403 38.5478 55.1554 38.2556 54.9856C37.9634 54.8158 37.7547 54.5824 37.5877 54.2852C37.4208 53.9881 37.3582 53.6485 37.3582 53.2877C37.3582 52.9269 37.4417 52.6086 37.5877 52.3114C37.7338 52.0143 37.9634 51.7809 38.2347 51.6111C38.7147 51.3139 39.3617 51.2715 39.9044 51.5049C40.1339 51.6111 40.3218 51.7596 40.4887 51.9294L40.6348 52.0992L41.0314 51.7172L40.8853 51.5474C40.6766 51.3139 40.4053 51.1229 40.1131 50.9956C39.4243 50.6772 38.5687 50.7409 37.9217 51.1229C37.546 51.3352 37.2747 51.6535 37.066 52.0355C36.8573 52.4176 36.7529 52.842 36.7529 53.2877C36.7529 53.6273 36.8155 53.9669 36.9408 54.264C37.066 54.5611 37.233 54.837 37.4417 55.0705C37.6504 55.304 37.9217 55.4737 38.2139 55.6011C38.506 55.7284 38.84 55.7921 39.1948 55.7921C39.5913 55.7921 39.967 55.7072 40.28 55.5374C40.614 55.3676 40.8853 55.1342 41.094 54.8583L41.1357 54.7946V52.7571H38.9026V53.3089H38.9443Z"
      fill="white"
    />
    <path d="M44.4121 51.3989H45.7896V55.7285H46.3322V51.3989H47.7096V50.847H44.4121V51.3989Z" fill="white" />
    <path
      d="M51.9049 52.7572H49.1709V50.847H48.6074V55.7285H49.1709V53.309H51.9049V55.7285H52.4685V50.847H51.9049V52.7572Z"
      fill="white"
    />
    <path
      d="M54.3883 53.309H56.0788V52.7572H54.3883V51.3989H56.5171V50.847H53.8457V55.7285H56.5797V55.1766H54.3883V53.309Z"
      fill="white"
    />
    <path
      d="M62.5701 53.5636C62.4866 53.4575 62.3822 53.3726 62.2779 53.309C62.1735 53.2453 62.0692 53.1816 61.9231 53.118C61.8396 53.0755 61.7353 53.0331 61.6309 52.9906L61.3387 52.8845C61.2552 52.8633 61.1718 52.8208 61.0883 52.7784C61.0048 52.7359 60.9422 52.6935 60.8796 52.651C60.817 52.6086 60.7752 52.5661 60.7126 52.5025C60.6709 52.46 60.65 52.3964 60.6291 52.3539C60.6083 52.2902 60.5874 52.2053 60.5874 52.1205C60.5874 52.0143 60.6083 51.9294 60.6291 51.8445C60.6709 51.7596 60.7126 51.696 60.7543 51.6323C60.817 51.5686 60.8796 51.5262 60.963 51.4837C61.0465 51.4413 61.1091 51.3988 61.1926 51.3988C61.61 51.2927 61.9648 51.4413 62.2361 51.7172L62.4031 51.887L62.7788 51.4625L62.6327 51.314C62.2153 50.8895 61.6726 50.6985 61.0674 50.847C60.9422 50.8683 60.817 50.9319 60.7126 50.9956C60.5874 51.0593 60.483 51.1442 60.3787 51.2503C60.2743 51.3564 60.1908 51.4837 60.1282 51.6323C60.0656 51.7809 60.0448 51.9507 60.0448 52.1205C60.0448 52.269 60.0656 52.3964 60.1074 52.5237C60.1491 52.651 60.2117 52.7572 60.2952 52.8633C60.3787 52.9482 60.4413 53.0331 60.5456 53.0967C60.6291 53.1604 60.7335 53.2241 60.8587 53.2877C60.963 53.3302 61.0674 53.3939 61.1718 53.4151L61.4848 53.5424C61.61 53.5849 61.6935 53.6273 61.777 53.6698C61.9648 53.7547 62.1109 53.882 62.2153 54.0093C62.2988 54.1367 62.3614 54.2852 62.3614 54.4763C62.3614 54.646 62.3196 54.7946 62.2361 54.9007C62.1527 55.0281 62.0483 55.113 61.9231 55.1766C61.7979 55.2403 61.6309 55.2828 61.4639 55.2828C60.9839 55.2828 60.6291 55.0705 60.3578 54.6673L60.2326 54.455L59.7734 54.7946L59.8987 54.9644C60.0865 55.2615 60.3161 55.4738 60.5665 55.6011C60.817 55.7497 61.1091 55.8133 61.4431 55.8133C61.8396 55.8133 62.1944 55.686 62.4657 55.4313C62.737 55.1766 62.8831 54.8371 62.8831 54.4338C62.8831 54.264 62.8623 54.1155 62.8205 53.9669C62.737 53.7971 62.6744 53.6698 62.5701 53.5636Z"
      fill="white"
    />
    <path d="M63.4883 51.3989H64.8657V55.7285H65.4084V51.3989H66.7858V50.847H63.4883V51.3989Z" fill="white" />
    <path d="M68.2461 50.847H67.6826V55.7285H68.2461V50.847Z" fill="white" />
    <path
      d="M71.5019 53.3089H73.1716V54.6036C73.0255 54.7946 72.8168 54.9432 72.6081 55.0493C72.0446 55.3252 71.335 55.2827 70.8132 54.9856C70.521 54.8158 70.3123 54.5824 70.1454 54.2852C69.9784 53.9881 69.9158 53.6485 69.9158 53.2877C69.9158 52.9269 69.9993 52.6086 70.1454 52.3114C70.2915 52.0143 70.521 51.7809 70.7923 51.6111C71.2724 51.3139 71.9194 51.2715 72.462 51.5049C72.6916 51.6111 72.8794 51.7596 73.0464 51.9294L73.1924 52.0992L73.589 51.7172L73.4429 51.5474C73.2342 51.3139 72.9629 51.1229 72.6707 50.9956C71.982 50.6772 71.1263 50.7409 70.4793 51.1229C70.1036 51.3352 69.8323 51.6535 69.6236 52.0355C69.4149 52.4176 69.3105 52.842 69.3105 53.2877C69.3105 53.6273 69.3732 53.9669 69.4984 54.264C69.6236 54.5611 69.7906 54.837 69.9993 55.0705C70.208 55.304 70.4793 55.4737 70.7715 55.6011C71.0637 55.7284 71.3976 55.7921 71.7524 55.7921C72.1489 55.7921 72.5246 55.7072 72.8377 55.5374C73.1716 55.3676 73.4429 55.1342 73.6516 54.8583L73.6933 54.7946V52.7571H71.4602V53.3089H71.5019Z"
      fill="white"
    />
    <path
      d="M79.1409 50.847H78.9948L77.1791 53.9032L75.3842 50.847H75.259H75.092H74.9668V55.7285H75.5303V52.2053L77.033 54.7097H77.1582H77.1999H77.346L78.8278 52.2053V55.7285H79.3913V50.847H79.2661H79.1409Z"
      fill="white"
    />
    <path
      d="M82.3969 50.847H82.2926H82.1674L80.1221 55.7285H80.7273L81.3534 54.2216H83.3361L83.9831 55.7285H84.5883L82.543 50.847H82.3969ZM81.583 53.6485L82.3343 51.8445L83.0857 53.6485H81.583Z"
      fill="white"
    />
    <path
      d="M68.6217 18.1416H68.7052H68.7887C71.2931 18.1416 74.7368 16.3801 77.6586 13.3239C81.8744 9.03673 83.6067 3.94309 81.5405 1.84196C80.9353 1.22648 80.1631 0.971794 79.2239 0.971794C77.596 0.971794 75.6133 1.75706 73.5471 3.15782C72.608 1.39626 70.7923 0.186523 68.6426 0.186523C66.5764 0.186523 64.6772 1.41749 63.738 3.15782C61.6719 1.75706 59.6892 1.05669 58.0613 1.05669C57.1221 1.05669 56.2456 1.31137 55.7447 1.92685C53.6785 4.02798 55.4942 9.1004 59.6266 13.4088C62.6737 16.4013 66.1173 18.1416 68.6217 18.1416ZM79.2239 2.37255C79.8291 2.37255 80.3509 2.54233 80.6014 2.81824C81.2066 3.43372 81.2066 4.66469 80.6848 6.23523C79.9961 8.16657 78.6187 10.3526 76.7194 12.2839C75.0916 13.9394 73.2758 15.2552 71.6271 15.9556C72.1488 14.194 71.2723 11.4774 69.5609 8.78205C70.0827 8.08167 70.6879 7.3813 71.3766 6.76581C73.9646 4.1341 77.0534 2.37255 79.2239 2.37255ZM68.7052 1.58728C70.3331 1.58728 71.7106 2.54233 72.3993 3.94309C71.7106 4.47367 71.0218 5.08916 70.3331 5.78953C69.7279 6.40501 69.2061 7.0205 68.7052 7.72087C68.1834 7.10539 67.6617 6.40501 67.0773 5.78953C66.3886 5.08916 65.6999 4.47367 65.0111 3.94309C65.6999 2.56356 67.0773 1.58728 68.7052 1.58728ZM56.7256 2.90313C56.976 2.64845 57.4978 2.45744 58.103 2.45744C60.2527 2.45744 63.3624 4.21899 66.0338 6.82948C67.933 8.76083 69.3105 10.862 69.9992 12.8782C70.5209 14.4487 70.5209 15.7646 69.9157 16.2952C69.6652 16.5499 69.1435 16.7409 68.5382 16.7409C66.3886 16.7409 63.2789 14.9793 60.6075 12.3688C56.5586 8.16657 55.6194 4.0492 56.7256 2.90313Z"
      fill="white"
    />
  </svg>
</template>
