<template>
  <button
    class="relative shrink-0 size-8 p-1.5 rounded-md transition-colors duration-200"
    :class="{ 'bg-white': isOpen }"
    :aria-label="ariaLabel"
    @click="isOpen = !isOpen"
  >
    <!-- Hamburger Icon -->
    <div
      class="flex flex-col gap-1.5 w-full transition-opacity duration-200"
      :class="{ 'opacity-0': isOpen, 'opacity-100': !isOpen }"
    >
      <span class="bg-white rounded-full w-full h-0.5"></span>
      <span class="bg-white rounded-full w-full h-0.5"></span>
      <span class="bg-white rounded-full w-full h-0.5"></span>
    </div>
    <!-- Close Icon -->
    <div
      class="absolute inset-0 size-full p-1.5 transition-opacity duration-200"
      :class="{ 'opacity-0': !isOpen, 'opacity-100': isOpen }"
    >
      <span class="relative top-1/2 block bg-blue rounded-full w-full h-0.5 transform rotate-45"></span>
      <span
        class="relative top-1/2 block bg-blue rounded-full w-full h-0.5 transform -rotate-45 -translate-y-0.5"
      ></span>
    </div>
  </button>
</template>
<script setup lang="ts">
const isOpen = defineModel({
  type: Boolean
})
const ariaLabel = computed(() => (isOpen.value ? 'Close menu' : 'Open menu'))
</script>
