<template>
  <div>
    <!-- fixed top div on native to make the safe top area blue -->
    <div
      v-if="isNative"
      class="bg-blue w-full fixed top-0 z-[100]"
      style="padding-top: env(safe-area-inset-top)"
    ></div>
    <!-- Fixed header top offset (height of header ) -->
    <div aria-hidden="true" :class="headerHeight" class="bg-blue"></div>
    <header
      role="banner"
      class="fixed z-[100] bg-blue text-white pb-4 w-full md:py-4 md:flex items-center"
      :class="[{ 'inset-y-0 xl:bottom-auto': mobileMenuOpen }, headerHeight]"
      :style="{ top: isNative ? 'env(safe-area-inset-top)' : '0px' }"
    >
      <!-- Buttons for Mobile -->
      <div class="flex mb-4 text-nowrap md:hidden">
        <div class="grow">
          <UButton
            label="Get urgent help"
            color="pink"
            to="/need-help-now"
            size="lg"
            block
            :ui="{ rounded: 'rounded-none' }"
          />
        </div>
        <div class="grow">
          <FrontendHidePageButton :block="true" size="lg" />
        </div>
        <div v-if="!isIframeView && simpleMobileNav" class="shrink px-4 -mt-1.5">
          <!-- Hamburger Icon for simple nav -->
          <HamburgerIcon v-model="mobileMenuOpen" tabindex="4" small />
        </div>
      </div>
      <div
        :class="{ 'max-md:hidden': simpleMobileNav }"
        class="container flex gap-x-10 items-center justify-between"
      >
        <!-- Logo (add option to pass in a custom logo. Default to HOH logo) -->
        <div class="flex flex-col gap-1">
          <NuxtLink v-if="iframeConfig?.logo" :to="iframeConfig.referralDomain || ''" target="_blank">
            <img
              :src="iframeConfig?.logo"
              class="w-auto"
              :class="[showPoweredBy ? 'max-h-[36px] md:max-h-[40px]' : 'max-h-[44px] md:max-h-[56px]']"
            />
          </NuxtLink>
          <NuxtLink
            v-else
            to="/"
            :target="iframeConfig?.uid ? '_blank' : ''"
            class="w-20 lg:w-16 xl:w-24"
            tabindex="1"
            aria-label="Link to the homepage"
          >
            <FrontendHeaderLogo />
          </NuxtLink>
          <p v-if="showPoweredBy" class="text-[0.7rem] text-[var(--hoh-color-primary-text-colour)]">
            Powered by Chasing the Stigma's
            <a :href="hostName" target="_blank" rel="nofollow" class="underline">Hub of Hope</a>
          </p>
        </div>

        <!-- Nav Links and Buttons -->
        <div class="flex items-center gap-x-11">
          <!-- Nav Links-->
          <nav v-if="!isIframeView" role="navigation" aria-label="main-navigation-iframe">
            <ul
              v-if="showLinks || mobileMenuOpen"
              class="hidden gap-10 font-brown text-sm xl:text-base"
              :class="{
                'xl:flex': !showLinks,
                'lg:flex': showLinks
              }"
            >
              <li v-for="link in headerLinks" :key="link.href">
                <NuxtLink
                  tabindex="5"
                  :to="link.href"
                  class="font-bold hover:text-gray-300 transition-colors duration-200"
                >
                  {{ link.name }}
                </NuxtLink>
              </li>
            </ul>
          </nav>

          <!-- Buttons -->
          <div class="flex gap-4 md:flex">
            <!-- Menu Buttons Desktop -->
            <NuxtLink to="/need-help-now">
              <UButton
                v-if="iframeConfig?.highLightSecondaryColor"
                label="Get urgent help"
                class="hidden md:block"
                color="green"
                tabindex="2"
              />
              <UButton
                v-else
                label="Get urgent help"
                tabindex="2"
                color="pink"
                class="hidden md:block focus:ring-white focus:ring-offset-1"
              />
            </NuxtLink>

            <FrontendHidePageButton class="hidden md:block" tabindex="3" />

            <!-- Hamburger Icon for non-simple nav -->
            <HamburgerIcon
              v-if="!isIframeView"
              v-model="mobileMenuOpen"
              tabindex="4"
              :class="{
                'lg:hidden': showLinks,
                'max-sm:hidden': simpleMobileNav
              }"
            />
          </div>
        </div>
      </div>
    </header>

    <!-- Mobile Nav -->
    <div
      v-if="mobileMenuOpen"
      id="mobile-nav-container"
      class="fixed inset-0 xl:hidden z-50 h-screen flex flex-col"
      :style="{ paddingTop: 'env(safe-area-inset-top)' }"
    >
      <!-- div to pad out header -->
      <div :class="headerHeight"></div>
      <FrontendMainNavMobile v-model="mobileMenuOpen" :header-links="headerLinks" />
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  showLinks: {
    type: Boolean,
    required: true
  },
  simpleMobileNav: Boolean
})
const mobileMenuOpen = ref(false)

const { hostName } = useRuntimeConfig().public

const { iframeConfig } = storeToRefs(useIframeConfigStore())
const showPoweredBy = computed(() => {
  // only show powered by if both logo and showPoweredBy are true
  return Boolean(iframeConfig.value?.logo && iframeConfig.value?.showPoweredBy)
})
const isIframeView = computed(() => {
  return Boolean(iframeConfig.value?.isLive)
})

const { isNative } = useRuntimeConfig().public

const headerHeight = computed(() => {
  return props.simpleMobileNav
    ? `h-[40px] md:h-[4.74rem] lg:h-[4.18rem] xl:h-[5.27rem]`
    : `h-[6.75rem] md:h-[4.74rem] lg:h-[4.18rem] xl:h-[5.27rem]`
})

const headerLinks = computed(() => {
  const links = [
    { name: 'Resources', href: '/resources', className: 'text-lg' },
    { name: 'About us', href: '/about-us', className: 'text-lg' },
    { name: 'Register service', href: '/register-your-service', className: 'text-lg' },
    { name: 'FAQs', href: '/faqs', className: 'text-lg' },
    { name: 'Get support', href: '/services', className: 'text-lg' }
  ]
  return links
})
</script>
