<template>
  <nav
    role="navigation"
    aria-label="mobile-navigation"
    class="transition-opacity duration-500 bg-blue flex grow overflow-auto pt-10"
  >
    <div class="container">
      <ul class="font-brown text-sm text-white">
        <li>
          <NuxtLink
            to="/"
            class="flex items-center gap-4 justify-between py-3.5 mb-2 last:mb-0 text-lg"
            @click="deferCloseMenu"
          >
            Home
            <UIcon name="heroicons:chevron-right-16-solid" class="size-6" />
          </NuxtLink>
        </li>
        <li v-for="link in headerLinks" :key="link.href">
          <NuxtLink
            :to="link.href"
            class="flex items-center gap-4 justify-between py-3.5 mb-2 last:mb-0"
            :class="link.className"
            @click="deferCloseMenu"
          >
            {{ link.name }}
            <UIcon name="heroicons:chevron-right-16-solid" class="size-6" />
          </NuxtLink>
        </li>
      </ul>

      <!-- Extra links on native -->
      <template v-if="isNative">
        <hr class="border-b border-white/50 mt-4 mb-1" />

        <ul class="font-brown text-sm text-white">
          <li v-for="link in nativeOnlyHeaderLinks" :key="link.href">
            <NuxtLink
              :to="link.href"
              class="flex items-center gap-4 justify-between py-3.5 mb-2 last:mb-0"
              :class="link.className"
              @click="deferCloseMenu"
            >
              {{ link.name }}
              <UIcon name="heroicons:chevron-right-16-solid" class="size-6" />
            </NuxtLink>
          </li>
        </ul>
      </template>
    </div>
  </nav>
</template>
<script setup lang="ts">
defineProps({
  headerLinks: {
    type: Array as PropType<{ name: string; href: string; className: string }[]>,
    required: true
  }
})
const mobileMenuOpen = defineModel({ type: Boolean, required: true })

const { isNative } = useRuntimeConfig().public

const deferCloseMenu = () => {
  setTimeout(() => {
    mobileMenuOpen.value = false
  }, 300)
}

// Disable scrolling when the mobile nav is open
onMounted(() => {
  document.body.classList.add('overflow-hidden', 'xl:overflow-auto')
})
onUnmounted(() => {
  document.body.classList.remove('overflow-hidden')
})

const nativeOnlyHeaderLinks = computed(() => {
  return [
    { name: 'Partnerships', href: '/partnerships', className: 'text-sm' },
    { name: 'Privacy policy', href: '/privacy-policy', className: 'text-sm' },
    { name: 'Cookie policy', href: '/cookie-policy', className: 'text-sm' },
    { name: 'Disclaimer', href: '/disclaimer', className: 'text-sm' },
    { name: 'Terms & conditions', href: '/terms-conditions', className: 'text-sm' }
  ]
})
</script>
